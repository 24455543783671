import React from 'react';
import { List, ListItem, ListItemText, Box, Typography } from '@mui/material';
import _ from 'lodash'

const Sidebar = ({ playlists, onSelectPlaylist }) => {
  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: 'black', // Fundo preto para o Sidebar
        color: 'white', // Texto branco para contraste
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Espaça o conteúdo e o rodapé
      }}
    >
      {/* Lista de playlists */}
      <List>
        {_.map(playlists || [], playlist => (
          <ListItem
            button
            key={playlist.playlist_id}
            onClick={() => onSelectPlaylist(playlist)}
            sx={{ '&:hover': { backgroundColor: '#333' } }} // Alterar cor no hover
          >
            <ListItemText primary={playlist.playlist_title} />
          </ListItem>
        ))}
      </List>

      {/* Rodapé com contato */}
      <Box sx={{ p: 2, textAlign: 'center', borderTop: '1px solid gray' }}>
        <Typography variant="body2" color="white">
          <a href="mailto:grionanuvem@gmail.com" style={{ color: 'white', textDecoration: 'none' }}>Contato</a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Sidebar;
