import React from 'react';
import { Grid, Typography } from '@mui/material';
import _ from "lodash"

import VideoCard from './VideoCard';

const VideoList = ({ title, videos }) => {
	return (
		<>
			<Typography variant="h4" gutterBottom>
				{title}
			</Typography>
			<Grid container spacing={2}>
				{_.map(videos, m => (
					<Grid item xs={12} sm={6} md={4}>
						<VideoCard
							videoId={m.video_id}
							title={m.title}
							description={""}
							thumbnailUrl={m.thumbnail_url}
						/>
					</Grid>
				))}
			</Grid>
		</>
	);
};

export default VideoList;
