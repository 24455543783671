import React, { useState } from 'react';
import { Card, CardContent, CardMedia, Typography, Dialog, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoCard = ({ videoId, title, description, thumbnailUrl }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {/* Card que exibe a thumbnail do vídeo */}
      <Card onClick={handleClickOpen} sx={{ cursor: 'pointer' }}>
        <CardMedia
          component="img"
          image={thumbnailUrl}
          alt={title}
          sx={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
          }}
        />
        <CardContent>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </Card>

      {/* Modal para exibir o player do YouTube */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogContent sx={{ position: 'relative' }}>
          {/* Botão de fechar o modal */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Player do YouTube embutido */}
          <iframe
            width="100%"
            height="400"
            src={`https://www.youtube.com/embed/${videoId}`}
            title={title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoCard;
