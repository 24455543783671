import React, { useEffect, useState } from 'react';
import { CssBaseline, Typography, Toolbar, Drawer } from '@mui/material';
import { IconButton, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';

import Sidebar from './components/Sidebar';
import VideoList from './components/VideoList';
import cfg from './cfg.json'

const drawerWidth = 200;

const App = () => {
	const [playlists, setPlaylists] = useState()
	const [select, setSelected] = useState()
	const [mobileOpen, setMobileOpen] = useState(false); // Controle para o menu mobile

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const handleSelectPlaylist = playlist => setSelected(playlist)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen); // Abre/fecha o menu no mobile
	};

	const getPlaylists = async () => {
		axios.get(cfg.supa_url, {
			headers: {
				apikey: cfg.supa_key
			}
		}).then(result => {
			let data = [
				{
					playlist_title: 'Home',
					description: 'Griô na Nuvem',
				}
			]
			if (result.status === 200) {
				data = [
					...data,
					...result.data
				]
			}
			setPlaylists(data)
		})
	}

	useEffect(() => {
		getPlaylists()
	}, [])

	return (
		<div 
			style={{ display: 'flex',
				backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`, // A imagem de fundo
				backgroundSize: 'cover', // A imagem cobre toda a área
				backgroundPosition: 'center', // Centralizar a imagem
				backgroundRepeat: 'no-repeat', // Evitar repetição da imagem
				minHeight: '100vh', // Altura mínima para cobrir a tela inteira
				padding: 3, // Espaçamento interno
				color: 'white', // Cor do texto 
			}}
		>
			<CssBaseline />
			{/* AppBar com botão de menu visível apenas no mobile */}
			{isMobile && (
				<div style={{ position: 'fixed', zIndex: theme.zIndex.drawer + 1, width: '100%' }}>
					<IconButton
						color="inherit"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 2, mt: 1 }}
					>
						<MenuIcon />
					</IconButton>
				</div>
			)}

			{/* Drawer para mobile (temporary) ou desktop (permanent) */}
			<Drawer
				variant={isMobile ? 'temporary' : 'permanent'}
				open={isMobile ? mobileOpen : true}
				onClose={handleDrawerToggle}
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				ModalProps={{
					keepMounted: true, // Mantém o drawer montado no mobile para performance
				}}
			>
				<Sidebar playlists={playlists} onSelectPlaylist={handleSelectPlaylist} />
			</Drawer>

			{/* Conteúdo principal da página */}
			<main style={{ flexGrow: 1, padding: theme.spacing(3) }}>
				<Toolbar />
				{select && select.videos ? (
					<VideoList
						title={select.playlist_title}
						videos={select.videos}
					/>
				) : (
					<Typography variant="h6">Griô Na Nuvem</Typography>
				)}
			</main>
		</div>
	);

	/*
	return (
		<Box sx={{
			display: 'flex',
			backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`, // A imagem de fundo
			backgroundSize: 'cover', // A imagem cobre toda a área
			backgroundPosition: 'center', // Centralizar a imagem
			backgroundRepeat: 'no-repeat', // Evitar repetição da imagem
			minHeight: '100vh', // Altura mínima para cobrir a tela inteira
			padding: 3, // Espaçamento interno
			color: 'white', // Cor do texto
		}}>
			<CssBaseline />

			<Drawer
				variant="permanent"
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
				}}
				anchor='left'
				open={true}
			>
				<Sidebar playlists={playlists} onSelectPlaylist={handleSelectPlaylist} />
			</Drawer>
			<Box
				component="main"
				sx={{ flexGrow: 1, p: 3, marginLeft: { md: `${drawerWidth}px` }, width: { xs: '80%', md: `calc(100% - ${drawerWidth}px)` } }}
			>
				<Toolbar />
				{select && select.videos ? (
					<VideoList
						title={select.playlist_title}
						videos={select.videos}
					/>
				) : (
					<Typography variant="h6">Griô Na Nuvem</Typography>
				)}
			</Box>
		</Box>
	);
	*/
};

export default App;